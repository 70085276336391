import React, { useState } from 'react'
import TransitionalModal from "../components/transitional";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { BURL } from '../../../services/base';
// Image
import SmallLoader from '../../widgets/SmallLoader';
import useAPI from '../../../hooks/useAPI';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import TenantHorizontalLinearStepper from '../components/tenantLinearStepper';
import axios from 'axios';

const Tenants = () => {
    const [loading, setLoading] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [success, setSuccess] = useState(null);
    const [properties, setProperties] = React.useState([]);
    const [tenants, setTenants] = React.useState([]);
    const [tenantsUnit, setTenantsUnit] = React.useState([]);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [tenantRoomId, setTenantRoomId] = React.useState(false);

    const { unitId, id } = useParams();

    const { deleteRequest } = useAPI();
    const [state, setState] = React.useState({
        opened: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opened } = state;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Formats as yyyy-mm-dd
    };

    const getTenantsByUnitRoom = React.useCallback(async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        setErrorText(null);
        const response = await axios.get(`${BURL}/admin/tenants`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        setLoading(false);

        if (response?.status) {
            const res = await response.data.data
            console.log('tenants', res);
            setTenants(res);
            // setRooms(res);
            // setUnitName(res[0].unit.name);
        } else {
            // setErrorText(response.message ?? 'Request Error');
        }
    }, [])

    React.useEffect(() => {
        getTenantsByUnitRoom();
    }, [getTenantsByUnitRoom])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleConfirmation = (roomId) => {
        setConfirmationOpen(true);
        setTenantRoomId(roomId)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
    };


    const handleClickOpen = () => {
        setShowModal(true);
    };

    const removeTenant = (newState) => async (propertyId) => {
        const token = localStorage.getItem('token');
        setConfirmLoading(true);
        setErrorText(null);

        try {
            const url = `${BURL}/admin/tenant-room`;
            const body = { propertyId, tenantRoomId };
            const response = await deleteRequest(url, body, token);
            setSuccess(response.message);
            if (response?.status) {
                setSuccess(response.message)
                getTenantsByUnitRoom();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
            setConfirmationOpen(false);
        }
    };

    const loader = <SmallLoader />

    return (
        <>
               <div className='w-auto bg-white py-8 overflow-x-hidden'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-12 bg-white overflow-x-hidden">
                    <p className='mb-[3rem] xl:mb-[5rem] font-semibold text-sm text-gray-400'>Tenants</p>
                    {loading && <SmallLoader />}
                    {(errorText && !loading) && errorText}
                    {!errorText && !loading && !tenants.length && <div className='mt-24 flex gap-5 lg:mx-0 justify-center text-center'>{(!errorText && !loading && !tenants.length)
                        && 'There is currently no tenant. Click CREATE TENANT to add a tenant to a property.'}
                    </div>}
                    {errorText && <Button onClick={getTenantsByUnitRoom}>Try again</Button>}
                    {!loading && tenants.length != 0 &&
                        <div className='overflow-x-auto my-table w-[100%] lg:w-[99%] xl:w-full'>
                            <table className='table-auto w-full text-[#6C6C6C]'>
                                <thead>
                                    <th>Room No</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Check In</th>
                                    <th>Check Out</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {tenants.map((tenant, index) =>
                                        <tr className='mb-[30rem]'>
                                            <td className='capitalize font-bold text-[#0FA958]'>{tenant?.tenancyHistory[0]?.room?.tag}</td>
                                            <td className='capitalize'>{tenant?.user.firstname}</td>
                                            <td className='capitalize'>{tenant?.user.lastname}</td>
                                            {/* <td className='capitalize'>{tenant.user.firstname} {tenant.user.lastname}</td> */}
                                            <td>{tenant.user.email}</td>
                                            <td>{formatDate(tenant?.tenancyHistory[0]?.checkIn)}</td>
                                            <td>{formatDate(tenant?.tenancyHistory[0]?.checkOut)}</td>
                                            <td className='flex gap-4 items-center'>
                                                < DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleConfirmation(tenant?.tenantRoom?.tenantRoomId)} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}

export default Tenants
