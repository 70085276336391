export const pptInitialValues = {
	name: '',
	type: '',
	state: '',
	address: '',
	country: '',
	street: '',
	city: '',
	zip: '',
	description: ''
}

export const assignAdminInitialValues = {
	adminId: '',
    roleId :'',
    propertyId: '',
}

export const tenantInitialValues = {
	roomId: '',
    propertyId: '',
    firstname :'',
    lastname:'',
    email:'',
    stayDuration:''
}

export const roomInitialValues = {
	unitId: '',
    propertyId: '',
    tag :''
}

export const pptTypes = ['Bungalow', 'Hostel', 'Duplex', 'Flat Apartment', 'Shops', 'Hall']