import { Checkbox } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import React from 'react'

const Profile = () => {
  return (
    <>
       <div className='w-full h-full min-h-screen bg-white flex'>
            {/* <DashboardNav /> */}
            <div className='w-full min-h-screen max-h-screen flex flex-col relative'>
                {/* <Header /> */}
                <div className='flex flex-col md:gap-8 p-7 md:p-16'>
                    <div className='hidden md:flex justify-between text-sm md:px-14 py-3 border-b border-green-600 font-medium text-[#6C6C6C]'>
                        <h2 className='cursor-pointer bg-[#EAFFF4] hover:bg-[#EAFFF4] p-3 transistion duration-300 ease-in-out'>My details</h2>
                        <h2 className='cursor-pointer hover:bg-[#EAFFF4] p-3 transistion duration-300 ease-in-out'>Tenancy details</h2>
                        <h2 className='cursor-pointer hover:bg-[#EAFFF4] p-3 transistion duration-300 ease-in-out'>Documents</h2>
                    </div>
                    <div className='flex md:hidden justify-between text-sm md:px-14 py-3 border-b border-green-600 font-medium text-[#6C6C6C]'>
                        <h2 className='cursor-pointer bg-[#EAFFF4] hover:bg-[#EAFFF4] p-3 transistion duration-300 ease-in-out'>Personal</h2>
                        <h2 className='cursor-pointer hover:bg-[#EAFFF4] p-3 transistion duration-300 ease-in-out'>Tenancy</h2>
                        <h2 className='cursor-pointer hover:bg-[#EAFFF4] p-3 transistion duration-300 ease-in-out'>Documents</h2>
                    </div>
                    <div className='flex flex-col md:flex-row gap-6 md:gap-12'>
                        <div className='relative cursor-pointer 6-[208px] h-[208px] md:w-[280px] md:h-[280px]'>
                            <img src="/profile-avatar.png" className='rounded-full' alt="" />
                            <div className='bg-[#0FA958] rounded-full text-white w-12 h-12 p-2 flex items-center justify-center absolute top-[9.5rem] left-[9.5rem] md:top-28 md:left-28 xl:top-36 xl:left-[10rem] cursor-pointer'>
                                <CameraAltIcon />
                            </div>
                        </div>
                        <form action="" className='w-full flex flex-col gap-14'>
                            <div className='flex flex-wrap gap-10 text-[#6C6C6C] text-sm'>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>First name</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='Funke' />
                                </div>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Last name</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='Orimolade' />
                                </div>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Phone number</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='08122222222' />
                                </div>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Email</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='funkeoris@gmail.com' />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label className='text-[13px] text-[#6C6C6C]'>Gender</label>
                                <div className='flex gap-6'>
                                    <div className='flex text-xs text-gray-700 text-left mb-8 items-center'>
                                        <Checkbox style={{padding: '0'}} size='small' />
                                        <span className='ml-1'>Male</span>
                                    </div>
                                    <div className='flex text-xs text-gray-700 text-left mb-8 items-center'>
                                        <Checkbox style={{padding: '0'}} size='small' />
                                        <span className='ml-1'>Female</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='w-full h-[466px] bg-[#F7FEFA] p-5 flex flex-col gap-4'>
                        <p className='text-sm font-semibold text-[#0FA958]'>Additional Information</p>
                        {/* <div className='flex flex-wrap gap-10 text-[#6C6C6C] text-sm'>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Age</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='24' />
                                </div>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Last </label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='Orimolade' />
                                </div>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Phone number</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='08122222222' />
                                </div>
                                <div className='flex flex-col gap-2 w-full xl:w-[272px] h-12'>
                                    <label className='text-sm text-[#6C6C6C]'>Email</label>
                                    <input type="text" className='p-2 border border-green-600 outline-none focus:outline-none rounded' placeholder='First name' value='funkeoris@gmail.com' />
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Profile
