import React from "react";
import {TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox, MenuItem} from "@mui/material";

const EditProfile = () => {
  const [profileImage, setProfileImage] = React.useState("https://via.placeholder.com/100");
  const [base64Image, setBase64Image] = React.useState(""); // Base64 string to send to server

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "Female",
    age: "",
    occupation: "",
    workplace: "",
    policeCustody: false,
    felony: false,
    emergencyContactName: "",
    emergencyContactPhone: "",
    emergencyContactRelation: "",
  });

  // Handle File Upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result); // Update image preview
        setBase64Image(reader.result); // Save Base64 string
      };
      reader.readAsDataURL(file); // Convert to Base64
    }
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const {name, value, type, checked} = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Simulate Final Submit to Server
  const handleFinalSubmit = () => {
    if (!formData.firstName || !formData.lastName || !formData.email) {
      alert("Please fill out all required fields.");
      return;
    }
    const payload = {...formData, profileImage: base64Image};
    console.log("Submitting Profile Data:", payload);

    // Example: Make API call here
    // fetch('/api/submit-profile', { method: 'POST', body: JSON.stringify(payload) });
  };

  return (
    <React.Fragment>
      <div className="max-w-4xl min-h-screen mx-auto p-6 rounded-lg">
        {/* Profile Section */}
        <div className="flex items-center space-x-4">
          <img
            src={profileImage}
            alt="Profile"
            className="w-32 h-32 rounded-full border-2 border-gray-300"
          />
          <label htmlFor="file-upload" className="cursor-pointer">
            <div className="flex items-center justify-center w-10 h-10 bg-green-600 text-white rounded-full">
              📸
            </div>
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>

        {/* Form Fields */}
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <TextField label="First name" variant="outlined" fullWidth />
          <TextField label="Last name" variant="outlined" fullWidth />
          <TextField label="Phone number" variant="outlined" fullWidth />
          <TextField label="Email" variant="outlined" fullWidth />
        </div>

        {/* Gender Selection */}
        <div className="mt-4">
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup row defaultValue="Female">
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
              <FormControlLabel value="Female" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>
        </div>

        {/* Additional Info */}
        <div className="mt-6 bg-white p-4 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-green-600">Additional Information</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
            <TextField label="Age" variant="outlined" fullWidth defaultValue="24 Years old" />
            <TextField
              label="What do you do?"
              variant="outlined"
              fullWidth
              select
              defaultValue="Student"
            >
              <MenuItem value="Student">Student</MenuItem>
              <MenuItem value="Employed">Employed</MenuItem>
            </TextField>
            <TextField
              label="Workplace/School"
              variant="outlined"
              fullWidth
              defaultValue="Ladoke Akintola University"
            />
          </div>
          <div className="flex flex-col sm:flex-row mt-4 space-y-2 sm:space-y-0 sm:space-x-4">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Have you ever been in police custody?"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Have you ever been convicted of a felony?"
            />
          </div>
        </div>

        {/* Emergency Contact */}
        <div className="mt-6 bg-white p-4 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-green-600">Emergency Contact Details/Next of Kin</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
            <TextField label="Full Name" variant="outlined" fullWidth defaultValue="Mr. Bayo Orimolade" />
            <TextField label="Phone Number" variant="outlined" fullWidth defaultValue="08030330330" />
            <TextField label="Relationship" variant="outlined" fullWidth defaultValue="Father" />
          </div>
        </div>
        <button
          onClick={handleFinalSubmit}
          className="mt-6 px-8 py-4 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Submit Profile
        </button>
      </div>
    </React.Fragment>
  )
}

export default EditProfile