import { Snackbar, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom'
import irrImg from '../../assets/auth-bg.svg'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import useAPI from '../../hooks/useAPI';
import { resetPasswordSchema, resetPasswordTokenSchema } from '../../schemas/validation.schema';

const ResetPasswordToken = () => {
    const { token } = useParams();
    const { POST } = useAPI();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [success, setSuccess] = useState(null);

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
    };

    const resetPassword = async (values) => {
        const resetPwrdPayload = {
            token,
            password: values.password
        }
        try {
            const response = await POST(`reset-password`, resetPwrdPayload);
            navigate('/login');
            console.log('Gjjd');
            if (response?.status) {
                setSuccess(response.message);
              } else {
                setErrorText(response.message);
              }
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
        }
    };

    // Beginning of formik form
    const { values, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            password: ''
        },
        validationSchema: resetPasswordTokenSchema,
        onSubmit: resetPassword
    });
    // End of formik form
    return (
        <div className='flex h-screen p-2 bg-house-pattern bg-tr-white justify-center bg-no-repeat bg-center bg-cover bg-blend-color-burn items-center'>
            <img draggable="false" className='fixed top-auto' width={615} src={irrImg} alt="bg" />
            <div className='z-10 px-10 pt-10 pb-20 m-auto w-[450px] shadow-xl bg-white text-center'>

                <h3 className='text-3xl font-bold text-austel-green'>Reset Password</h3>

                <span className='text-austel-green text-sm block mb-4 font-semibold'></span>
                <form onSubmit={handleSubmit}>
                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}

                    <FormControl sx={{ width: '100%', marginTop: 1 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password" size='small'>Password</InputLabel>
                        <Input
                            type="password"
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {touched.password && errors.password && <div className='text-red-600 text-xs text-left'>{errors.password}</div>}
                    <button className='text-white bg-austel-green mt-8 py-3 px-20 my-2 block mx-auto text-sm' type='submit'>{loading ? 'Loading...' : "Reset Password"}</button>
                    {/* <span className="text-sm mt-2 text-gray-400">Remember password? <Link to="/login" className="text-austel-green">Login</Link></span> */}
                </form>

            </div>
            {success && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {success}
                </Alert>
            </Snackbar>}
        </div>
    )
}

export default ResetPasswordToken
