import React from 'react'
import Chart from '../components/charts'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import TransitionalModal from "../components/transitional";
import { Link } from 'react-router-dom';

function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Txn !D: 1992346746721', 'Funke Orimolade', '₦ 100,000', 'July 27, 2022',),
    createData('Txn !D: 1992346746721', 'Funke Orimolade', '₦ 100,000', 'July 27, 2022',),
    createData('Txn !D: 1992346746721', 'Funke Orimolade', '₦ 100,000', 'July 27, 2022',),
    createData('Txn !D: 1992346746721', 'Funke Orimolade', '₦ 100,000', 'July 27, 2022',),
    createData('Txn !D: 1992346746721', 'Funke Orimolade', '₦ 100,000', 'July 27, 2022',),
    createData('Txn !D: 1992346746721', 'Funke Orimolade', '₦ 100,000', 'July 27, 2022',),
];

export default function home() {
    return (
        <React.Fragment>
            <div className="md:px-10 sm:px-3 mt-5 md:mt-6 flex justify-end items-center pt-3 bg-white mb-0">
                {/* <h1 className="font-bold">  Dashboard</h1> */}
                <TransitionalModal />
            </div>
            <div className='w-full bg-white'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-[1.8em] bg-white w-full mb-10">
                    {/* <div className='pb-10 w-full'> */}
                    <p className='mb-5 font-light text-sm text-gray-400'>Your Properties at a glance</p>
                    <div className='flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap md:jsutify-between gap-5 lg:mx-0'>
                        <div className='bg-[#EADAFF] px-6 py-2 h-[140px] w-full md:w-[48%] xl:w-1/3 rounded-md text-[#6C6C6C] flex flex-col gap-4'>
                            <span className='text-[12px]'>Properties</span>
                            <div className='flex justify-between'>
                                <p className='flex flex-col mt-2 md:mt-1'>
                                    <span className='text-[40px] md:text-[45px] font-semibold'>3</span>
                                    <span className='text-[14px] -mt-2 md:-mt-3'>Properties</span>
                                </p>
                                <div>
                                    <img src="/mdi_home.png" className='' alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='bg-[#CFEEDE] px-6 py-2 h-[140px] w-full md:w-[48%] xl:w-1/3 rounded-md text-[#6C6C6C] flex flex-col gap-4'>
                            <span className='text-[12px]'>Active Leases</span>
                            <div className='flex justify-between'>
                                <p className='flex flex-col mt-2 md:mt-1'>
                                    <span className='text-[40px] md:text-[45px] font-semibold'>58</span>
                                    <span className='text-[14px] -mt-2 md:-mt-3'>Active leases</span>
                                </p>
                                <div>
                                    <img src="/lease.png" className='' alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='bg-[#CDE2FB] px-6 py-2 h-[140px] w-full md:w-[48%] xl:w-1/3 rounded-md text-[#6C6C6C] flex flex-col gap-4'>
                            <span className='text-[12px]'>Ending Leases</span>
                            <div className='flex justify-between'>
                                <p className='flex flex-col mt-2 md:mt-1'>
                                    <span className='text-[40px] md:text-[45px] font-semibold'>15</span>
                                    <span className='text-[14px] -mt-2 md:-mt-3'>Leases ending &lt;90 days</span>
                                </p>
                                <div>
                                    <img src="/end-lease.png" className='' alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='bg-[#EB042D33] px-6 py-2 h-[140px] w-full md:w-[48%] xl:w-1/3 rounded-md text-[#6C6C6C] flex flex-col gap-4'>
                            <span className='text-[12px]'>Delinquencies</span>
                            <div className='flex justify-between'>
                                <p className='flex flex-col mt-2 md:mt-1'>
                                    <span className='text-[40px] md:text-[45px] font-semibold'>7</span>
                                    <span className='text-[14px] -mt-2 md:-mt-3'>Tenant delinquencies</span>
                                </p>
                                <div>
                                    <img src="/delinquencies.png" className='' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                {/* Chart section */}
                <div className='w-full px-[1.22rem] md:px-[1.8em] flex flex-col md:flex-row gap-10 mb-10'>
                    <div className="w-full md:w-3/5 lg:w-2/3 bg-white shadow-lg rounded-lg px-6 py-3">
                        <p className='text-[#6C6C6C] text-[14px] md:text-[16px] font-semibold'>Income/Expenditure Analytics</p>
                        <div className='p-5'>
                            <Chart />
                        </div>
                    </div>
                    {/* Tenant Complaints */}
                    <div className="w-full md:w-2/5 lg:w-1/3 bg-white shadow-lg rounded-lg px-4 py-3">
                        <div className='flex justify-between'>
                            <p className='text-[#2DB46D] text-[14px] md:text-[16px] font-semibold'>Tenant Complaints</p>
                            <Link className='text-[#6C6C6C] text-[8px] md:text-[10px] mt-2'>View all</Link>
                        </div>
                        <div className='flex flex-col gap-5 mt-4'>

                            <div className='flex justify-between'>
                                <div className='flex gap-3'>
                                    <img src="/avatar.png" className='rounded-full' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 text-[#6C6C6C]'>
                                        <h3 className='text-[11px] lg:text-[14px] font-semibold'>Funke Orimolade</h3>
                                        <p className='text-[7px] lg:text-[10px]'>Abraham Adesanya Hostel <span className='text-[8px] lg:text-[10px] font-bold'>.</span> Aug 22, 2022 </p>
                                    </div>
                                </div>
                                <PlayArrowIcon className='flex items-center ml-9 mt-2 cursor-pointer text-[#6C6C6C]' />
                            </div>

                            <div className='flex justify-between'>
                                <div className='flex gap-3'>
                                    <img src="/avatar.png" className='rounded-full' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 text-[#6C6C6C]'>
                                        <h3 className='text-[11px] lg:text-[14px] font-semibold'>Funke Orimolade</h3>
                                        <p className='text-[7px] lg:text-[10px]'>Abraham Adesanya Hostel <span className='text-[8px] lg:text-[10px] font-bold'>.</span> Aug 22, 2022 </p>
                                    </div>
                                </div>
                                <PlayArrowIcon className='flex items-center ml-9 mt-2 cursor-pointer text-[#6C6C6C]' />
                            </div>

                            <div className='flex justify-between'>
                                <div className='flex gap-3'>
                                    <img src="/avatar.png" className='rounded-full' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 text-[#6C6C6C]'>
                                        <h3 className='text-[11px] lg:text-[14px] font-semibold'>Funke Orimolade</h3>
                                        <p className='text-[7px] lg:text-[10px]'>Abraham Adesanya Hostel <span className='text-[8px] lg:text-[10px] font-bold'>.</span> Aug 22, 2022 </p>
                                    </div>
                                </div>
                                <PlayArrowIcon className='flex items-center ml-9 mt-2 cursor-pointer text-[#6C6C6C]' />
                            </div>

                            <div className='flex justify-between'>
                                <div className='flex gap-3'>
                                    <img src="/avatar.png" className='rounded-full' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 text-[#6C6C6C]'>
                                        <h3 className='text-[11px] lg:text-[14px] font-semibold'>Funke Orimolade</h3>
                                        <p className='text-[7px] lg:text-[10px]'>Abraham Adesanya Hostel <span className='text-[8px] lg:text-[10px] font-bold'>.</span> Aug 22, 2022 </p>
                                    </div>
                                </div>
                                <PlayArrowIcon className='flex items-center ml-9 mt-2 cursor-pointer text-[#6C6C6C]' />
                            </div>

                            <div className='flex justify-between'>
                                <div className='flex gap-3'>
                                    <img src="/avatar.png" className='rounded-full' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 text-[#6C6C6C]'>
                                        <h3 className='text-[11px] lg:text-[14px] font-semibold'>Funke Orimolade</h3>
                                        <p className='text-[7px] lg:text-[10px]'>Abraham Adesanya Hostel <span className='text-[8px] lg:text-[10px] font-bold'>.</span> Aug 22, 2022 </p>
                                    </div>
                                </div>
                                <PlayArrowIcon className='flex items-center ml-9 mt-2 cursor-pointer text-[#6C6C6C]' />
                            </div>

                            <div className='flex justify-between'>
                                <div className='flex gap-3'>
                                    <img src="/avatar.png" className='rounded-full' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 text-[#6C6C6C]'>
                                        <h3 className='text-[11px] lg:text-[14px] font-semibold'>Funke Orimolade</h3>
                                        <p className='text-[7px] lg:text-[10px]'>Abraham Adesanya Hostel <span className='text-[8px] lg:text-[10px] font-bold'>.</span> Aug 22, 2022 </p>
                                    </div>
                                </div>
                                <PlayArrowIcon className='flex items-center ml-9 mt-2 cursor-pointer text-[#6C6C6C]' />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Recent Payments */}
                <div className='w-full px-[1.22rem] md:px-[1.8em] flex flex-col md:flex-row gap-10'>
                    <div className="w-full bg-white shadow-lg rounded-lg px-6 py-3">
                        <div className='flex justify-between'>
                            <p className='text-[#2DB46D] text-[14px] md:text-[16px] font-semibold'>Recent Payments</p>
                            <Link className='text-[#6C6C6C] text-[8px] md:text-[10px] mt-2'>View all</Link>
                        </div>

                        <TableContainer className='mt-4' component={Paper} style={{ border: 0, backgroundColor: 'transparent', boxShadow: 'none', color: '#6C6C6C' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ border: 0, backgroundColor: 'transparent' }}>
                                <TableHead>
                                    <TableRow style={{ borderBottom: 0 }}>
                                        <TableCell style={{ borderBottom: 0 }}>Transaction ID</TableCell>
                                        <TableCell style={{ borderBottom: 0 }}>Name</TableCell>
                                        <TableCell style={{ borderBottom: 0 }}>Amount</TableCell>
                                        <TableCell style={{ borderBottom: 0 }}>Date/Time</TableCell>
                                        <TableCell style={{ borderBottom: 0 }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ borderBottom: 0 }}>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ borderBottom: 0 }} component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell style={{ borderBottom: 0 }}>{row.calories}</TableCell>
                                            <TableCell style={{ borderBottom: 0 }}>{row.fat}</TableCell>
                                            <TableCell style={{ borderBottom: 0 }}>{row.carbs}</TableCell>
                                            <TableCell style={{ borderBottom: 0 }} align="right"><PlayArrowIcon className='flex items-center ml-9 cursor-pointer text-[#6C6C6C]' /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
// console.log(card[1]);
