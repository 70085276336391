import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, TextField, MenuItem, Snackbar, IconButton, SnackbarOrigin, Alert, TextareaAutosize, DialogContentText, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import useAPI from '../../../hooks/useAPI';
import { BURL } from '../../../services/base';
import { pptTypes } from '../../../services/utils';
import { useFormik } from 'formik';
import SmallLoader from '../../widgets/SmallLoader';
import { RemoveRedEye } from '@mui/icons-material';
import { HorizontalLinearAssignAdminStepper } from '../components/linearStepper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
// interface MyState extends SnackbarOrigin {
//     open: boolean;
//   }

function PropertyDetails() {
    // const [property, setProperty] = useState(null);
    const [property, setProperty] = useState({
        name: '',
        address: '',
        type: '',
        street: '',
        state: '',
        city: '',
        country: '',
        zip: '',
        propertyId: '',
        addressId: ''
    });
    const [updateProperty, setUpdateProperty] = useState({
        name: '',
        address: '',
        type: '',
        street: '',
        state: '',
        city: '',
        country: '',
        zip: '',
        propertyId: '',
        addressId: '',
        adminPropertyRoles: []
    });

    const [loading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [contentModalOpen, setContentModalOpen] = useState(false);
    const [unitModalOpen, setUnitModalOpen] = useState(false);
    const [unitUpdateModalOpen, setUnitUpdateModalOpen] = useState(false);
    const [unitDetails, setUnitDetails] = useState({
        name: '',
        description: '',
        propertyId: 0,
        unitId: 0
    });
    const [unitData, setUnitData] = useState({
        name: '',
        description: '',
        propertyId: '',
        rooms: []
    })
    const [errorText, setErrorText] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [unitId, setUnitId] = useState(null);
    const [adminEmail, setAdminEmail] = useState('');
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const { POST, deleteRequest } = useAPI();
    const { id } = useParams();
    const typeList = pptTypes;
    const token = localStorage.getItem('token');

    const formik = useFormik({
        initialValues: {
            adminId: Math.floor(Math.random() * 90) + 10,
            roleId: '',
            propertyId: id,
        },
    });

    const { values, handleChange, handleSubmit, resetForm } = formik;
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;


    const handleUnitUpdateModal = (unit) => {
        setUnitUpdateModalOpen(true);
        console.log('Unit Details', unit);
        setUnitDetails({
            name: unit.name,
            description: unit.description,
            propertyId: unit.propertyId,
            unitId: unit.id
        });
    };

    const handleClose = () => {
        setModalOpen(false);
        setUnitModalOpen(false);
        setUnitUpdateModalOpen(false);
    };

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
    };

    const handleConfirmation = (unitId) => {
        setConfirmationOpen(true);
        setUnitId(unitId)
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getUTCDate()).padStart(2, '0');
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = dateObj.getUTCFullYear();
        return `${day}-${month}-${year}`;
    };

    const getAllRoles = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BURL}/app/roles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const res = response.data.data;
            setRoles(res);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchPropertyDetails = async () => {
        // const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BURL}/admin/properties/${id}`, {
                params: {
                    withUnits: true,
                    withAdmins: true,
                    withAddress: true,
                    withRooms: true,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const prop_details = await response.data.data;
            setProperty(response.data.data);

            setUpdateProperty({
                name: prop_details.name,
                address: prop_details.address.address,
                type: prop_details.type,
                street: prop_details.address.street,
                state: prop_details.address.state,
                city: prop_details.address.city,
                country: prop_details.address.country,
                zip: prop_details.address.zip,
                propertyId: prop_details.id,
                addressId: prop_details.addressId,
                adminPropertyRoles: prop_details.adminPropertyRoles
            });
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
            console.log('update', updateProperty);

        }
    };
    useEffect(() => {
        fetchPropertyDetails();
        getAllRoles();
    }, [id]);


    const handleUpdateChange = (e) => {
        const { name, value } = e.target;
        setUpdateProperty((prevUpdateProperty) => ({
            ...prevUpdateProperty,
            [name]: value,
        }));
    };

    const handleUnitChange = (e) => {
        const { name, value } = e.target;
        setUnitData((prevUnitData) => ({
            ...prevUnitData,
            [name]: value,
        }));

        setUnitDetails((prevUnitDetails) => ({
            ...prevUnitDetails,
            [name]: value,
        }));
    };

    const handleUpdateSubmit = (newState) => async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorText(null);
        try {
            const response = await POST(`admin/properties/update`, updateProperty);
            if (response?.status) {
                setState({ ...newState, open: true });
                setSuccess(response.message);
            } else {
                setErrorText(response.message);
                console.log('Error', response?.data);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
        }
    };


    const addNenUnit = (newState) => async () => {
        setConfirmLoading(true);
        unitData.propertyId = property.id;
        try {
            const response = await POST(`admin/properties/units/new`, unitData);
            if (response?.status) {
                setState({ ...newState, open: true });
                setSuccess(response.message);
                setUnitModalOpen(false);
                fetchPropertyDetails();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setConfirmLoading(false);
        }
    }

    const updateUnit = (newState) => async () => {
        setConfirmLoading(true);
        setErrorText(null);
        console.log('unitDetails2', unitDetails);
        try {
            const response = await POST(`admin/properties/units/update`, unitDetails);
            if (response?.status) {
                setState({ ...newState, open: true });
                setSuccess(response.message);
                setUnitUpdateModalOpen(false);
                fetchPropertyDetails();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setConfirmLoading(false);
        }
    }

    const deleteUnit = (newState) => async () => {
        const token = localStorage.getItem('token');
        setConfirmLoading(true);
        setErrorText(null);

        try {
            const url = `${BURL}/admin/unit`;
            const body = { propertyId: id, unitId, force: true };

            const response = await deleteRequest(url, body, token);
            setState({ ...newState, open: true });
            setSuccess(response.message);
            if (response?.status) {
                fetchPropertyDetails();
            } else {
                setErrorText(response.message);
            }
        } catch (err) {
            setErrorText(err);
        } finally {
            setConfirmLoading(false);
            setConfirmationOpen(false);
        }
    };

    const loader = <SmallLoader />
    return (
        <>
            {loading && <SmallLoader />}
            {(errorText && !loading) && errorText}
            {errorText && <Button onClick={fetchPropertyDetails}>Try again</Button>}
            <div className="px-5 md:px-10 sm:px-3 flex flex-col gap-10 mt-5 md:mt-4 pt-3 bg-white mb-0">
                {/* Property Details */}
                <div>
                    <div className='w-full text-[#6C6C6C] flex justify-between'>
                        <div className='flex gap-3'>
                            <Link to={'/dashboard/properties'} className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Properties</Link>
                            <span className='mt-3 text-[12px] md:text-[14px]'> > Property Details</span>
                        </div>
                        {!isEditing ?
                            <div className='flex gap-2 items-center cursor-pointer' onClick={() => setIsEditing(!isEditing)}>
                                <EditIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '5' }} />
                                <span className='hidden md:flex text-[14px]'>Edit</span>
                            </div> :
                            <button className='cursor-pointer' onClick={handleUpdateSubmit({ vertical: 'bottom', horizontal: 'right' })}>Save Changes</button>
                        }
                    </div>
                    <div className='w-full flex gap-[4.5rem] xl:gap-5 mt-[42px] pb-[2rem] border-b-[0.5px] border-[#000000]'>
                        <div className='hidden md:flex w-full md:w-1/3 lg:w-[20%]'>
                            <div className='flex flex-col gap-1 rounded-md absolute'>
                                <img src="/house_img.png" alt="Property Image" />
                                <div className='flex gap-1'>
                                    <img src="/house_img.png" className='w-[68px] h-[65px] rounded-[5px]' alt="Property Image" />
                                    <img src="/house_img.png" className='w-[68px] h-[65px] rounded-[5px]' alt="Property Image" />
                                    <img src="/house_img.png" className='w-[68px] h-[65px] rounded-[5px]' alt="Property Image" />
                                </div>
                                <button className='bg-[#0FA95833] p-2 rounded-[5px] text-[#6C6C6C] text-[10px] md:text-[12px]'>Add/Edit Images</button>
                            </div>
                        </div>
                        <div className='w-full md:w-2/3 lg:w-[60%] flex flex-col gap-5 md:gap-8'>
                            <div className='w-full grid md:grid-cols-[58%,40%] gap-5'>
                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                    <label htmlFor="" className=''>Property Name</label>
                                    <input type="text" disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] p-3 outline-none focus:outline-none focus:border-2 rounded-[5px]' name="name" value={updateProperty?.name} onChange={handleUpdateChange} placeholder="Abraham Adesanyan Hostel" />
                                </div>

                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                    <label htmlFor="">Property Type</label>
                                    <select name="type" disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] px-3 py-4 outline-none focus:outline-none focus:border-2 rounded-[5px]' id="" placeholder='Property Type' value={updateProperty?.type} onChange={handleUpdateChange}>
                                        {typeList.map((type, index) =>
                                            <option key={index} value={type || ''}>{type}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='w-full grid md:grid-cols-[35%,63%] gap-5'>
                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                    <label htmlFor="">State</label>
                                    <select name="state" disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] px-3 py-4 outline-none focus:outline-none focus:border-2 rounded-[5px]' placeholder='Property Type' value={updateProperty.state} onChange={handleUpdateChange}>
                                        <option value="Abia">Abia</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Ogun">Ogun</option>
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                    <label htmlFor="" className=''>Street Address</label>
                                    <input type="text" disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] p-3 outline-none focus:outline-none focus:border-2 rounded-[5px]' placeholder="Strret Address" name="address" value={updateProperty.address} onChange={handleUpdateChange} />
                                </div>
                            </div>
                            <div className='w-full grid md:flex gap-5'>
                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] w-full md:w-[32.6%]'>
                                    <label htmlFor="">Country</label>
                                    <select disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] px-3 py-4 outline-none focus:outline-none focus:border-2 rounded-[5px]' name="country" placeholder='Country' value={updateProperty.country} onChange={handleUpdateChange}>
                                        <option value="country">Select country</option>
                                        <option value="nigeria">Nigeria</option>
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] w-full md:w-[32.6%]'>
                                    <label htmlFor="" className=''>City</label>
                                    <input type="text" disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] p-3 outline-none focus:outline-none focus:border-2 rounded-[5px]' name='city' placeholder="City" value={updateProperty.city} onChange={handleUpdateChange} />
                                </div>
                                <div className='flex flex-col gap-2 text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] w-full md:w-[32.6%]'>
                                    <label htmlFor="" className=''>Zip/Postal Code</label>
                                    <input type="text" disabled={!isEditing} className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] p-3 outline-none focus:outline-none focus:border-2 rounded-[5px]' placeholder="Zip/Postal Code" value={updateProperty.zip} onChange={handleUpdateChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Property Managers */}
                <Dialog fullWidth maxWidth='sm' open={modalOpen} TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description" onClose={handleClose}>
                    {/* <form onSubmit={handleSubmit}> */}
                    {/* <DialogContent>
                        <div className="pt-5 gap-2 flex flex-col">
                            <div className='w-full'>
                                <TextField fullWidth name="adminEmail" label="Email" type='email' value={adminEmail} onChange={handleadminEmailChange} variant="outlined" />
                            </div>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                name='roleId'
                                value={values.roleId}
                                onChange={handleChange}
                                MenuProps={{
                                    style: {
                                        maxHeight: 300,
                                        width: 100
                                    },
                                }}
                            >
                                {roles.map((role) => (
                                    <MenuItem value={role.id}>{role.name}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={fetchAdminWithEmail({ vertical: 'bottom', horizontal: 'right' })}>Search</Button>
                    </DialogActions> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <HorizontalLinearAssignAdminStepper open={modalOpen} setOpen={setModalOpen} fetchPropertyDetails currentPropertyId={id} />
                        </DialogContentText>
                    </DialogContent>
                    {/* </form> */}
                </Dialog >
                <div className='flex flex-col gap-4 pb-[2rem] border-b-[0.5px] border-[#000000]'>
                    <div className='w-full text-[#6C6C6C] flex justify-between'>
                        <div className='flex gap-3'>
                            <h1 className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Property Managers</h1>
                        </div>
                        <div onClick={() => setModalOpen(true)} className='flex gap-2 items-center cursor-pointer'>
                            <AddIcon className='flex items-center text-[#6C6C6C]' sx={{ fontSize: '5' }} />
                            <span className='hidden md:flex text-[14px]'>Add Admin</span>
                        </div>
                    </div>
                    {property?.adminPropertyRoles ? (
                        <div className='w-full flex flex-col gap-5 md:gap-8'>
                            {property.adminPropertyRoles.map((role, index) => (
                                <div key={index} className='w-full grid md:flex gap-5'>
                                    <div className='flex flex-col gap-2 w-full md:w-[60%] text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] cursor-pointer'>
                                        <label htmlFor="" className=''>Admin Name</label>
                                        <input type="text" disabled className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] p-3 outline-none focus:outline-none focus:border-2 rounded-[5px] capitalize' placeholder="Admin Name" value={role.admin.user.firstname} onChange={handleChange} />
                                    </div>
                                    <div className='flex flex-col gap-2 w-full md:w-[30%] text-[#6C6C6C] text-[10px] md:text-[12px] font-[300]'>
                                        <label htmlFor="">Role/Permission</label>
                                        <select name="role" disabled className='disabled:bg-gray-200 disabled:border-gray-200 border-[0.75px] border-[#0FA958] px-3 py-4 outline-none focus:outline-none focus:border-2 rounded-[5px]' id="" placeholder='Admin Role' value={role.role.id} onChange={handleChange}>
                                            {roles.map((role) => (
                                                <option value={role.id}>{role.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='my_property hidden w-full md:w-[10%] md:flex md:gap-4 justify-center items-center mt-5 ml-3'>
                                        <EditIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} />
                                        <ArchiveIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} />
                                        <DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} />
                                    </div>
                                </div>
                            )
                            )}
                        </div>
                    )
                        : (
                            <p>No admin roles available</p>
                        )}
                </div>

                {/* Unit Details */}
                {property?.units ? (
                    <div className='flex flex-col gap-4 pb-[2rem]'>
                        <div className='w-full text-[#6C6C6C] flex justify-between'>
                            <div className='flex gap-3'>
                                <h1 className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Unit Details</h1>
                            </div>
                            <div onClick={() => setUnitModalOpen(true)} className='flex gap-2 items-center cursor-pointer'>
                                <AddIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '5' }} />
                                <span className='hidden md:flex text-[14px]'>Add Unit</span>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 w-full text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] overflow-x-auto'>
                            {/* List Title */}
                            <div className='flex w-full justify-between font-bold px-4'>
                                <span className='w-80px]'>Unit Name</span>
                                <span className='w-[80px]'>Description</span>
                                <span className='w-[80px]'>No Of Rooms</span>
                                <span className='w-[80px]'>Status</span>
                                <span className='w-[80px]'>Tenants</span>
                                <span className='w-[80px]'>Date</span>
                                <span className='w-[80px]'>Action</span>
                            </div>
                            {/* Data */}
                            {property.units.map((unit, index) =>
                                <Link key={index} className='bg-[#F1F1F1] flex justify-between px-4 py-3 cursor-pointer'>
                                    <span className='capitalize w-[80px] text-[#0FA958]'>{unit.name}</span>
                                    {unit.description == null ? (

                                        <span className='w-[80px] font-[20px'>
                                            -</span>
                                    )
                                        : (
                                            <span className='w-[80px]'>
                                                {unit.description}</span>
                                        )

                                    }
                                    <span className='w-[80px]'>{unit.roomCount}</span>
                                    <span className='w-[80px]'>
                                        <div className='flex flex-col gap-0 lg:gap-1'>
                                            <div className='w-[90px] flex justify-between'>
                                                <div className='flex gap-1 items-center'>
                                                    <div className='w-[8px] h-[8px] bg-[#0C9A00] rounded-full flex items-center justify-items-center'></div>
                                                    <span className=''>Occupied</span>
                                                </div>
                                                <span>{unit.occupiedRooms}</span>
                                            </div>
                                            <div className='w-[90px] flex justify-between'>
                                                <div className='flex gap-1 items-center'>
                                                    <div className='w-[8px] h-[8px] bg-[#EB042D] rounded-full flex items-center justify-items-center'></div>
                                                    <span className=''>Empty</span>
                                                </div>
                                                <span>{unit.emptyRooms}</span>
                                            </div>
                                        </div>
                                    </span>
                                    <span className='w-[80px]'>{unit.tenantCount} Tenant</span>
                                    <span className='w-[80px]'>{formatDate(unit.createdAt)}</span>
                                    <div className='w-[80px] flex gap-4 items-center'>
                                        <Link to={`/dashboard/property/${id}/unit/${unit.id}`}>
                                            < RemoveRedEye className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} />
                                        </Link>
                                        < EditIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleUnitUpdateModal(unit)} />
                                        < DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '18px' }} onClick={() => handleConfirmation(unit.id)} />
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                )
                    : (
                        <p>No Unit available</p>
                    )
                }
                {/* New Unit Modal */}
                <Dialog fullWidth maxWidth='sm' open={unitModalOpen} onClose={handleClose}>
                    <DialogTitle>Add Unit</DialogTitle>
                    <DialogContent>
                        <div className="pt-5 gap-2 flex flex-col">
                            <div className="flex gap-2">
                                <div className='w-[50%]'>
                                    <TextField fullWidth name="name" label="Name" type='text' value={values.name} onChange={handleUnitChange} variant="outlined" />
                                </div>
                                <div className='w-[50%]'>
                                    <TextField fullWidth name="description" label="Description" type='text'
                                        value={values.description} onChange={handleUnitChange} variant="outlined" />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={addNenUnit({ vertical: 'bottom', horizontal: 'right' })}>
                            {!confirmLoading && <span>Save</span>}

                            {confirmLoading && loader}
                        </Button>
                    </DialogActions>
                </Dialog >

                {/* Update Unit Modal */}
                <Dialog fullWidth maxWidth='sm' open={unitUpdateModalOpen} onClose={handleClose}>
                    <DialogTitle>Update Unit</DialogTitle>
                    <DialogContent>
                        <div className="pt-5 gap-2 flex flex-col">
                            <div className="flex gap-2">
                                <div className='w-[50%]'>
                                    <TextField fullWidth name="name" label="Name" type='text' value={unitDetails.name} onChange={handleUnitChange} variant="outlined" />
                                </div>
                                <div className='w-[50%]'>
                                    <TextField fullWidth name="description" label="Description" type='text'
                                        value={unitDetails.description} onChange={handleUnitChange} variant="outlined" />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={updateUnit({ vertical: 'bottom', horizontal: 'right' })}>
                            {!confirmLoading && <span>Save</span>}

                            {confirmLoading && loader}
                        </Button>
                    </DialogActions>
                </Dialog >
            </div>
            <Dialog
                open={confirmationOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete unit?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this unit?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationOpen(false)}>No</Button>
                    <Button sx={{ color: 'red' }} onClick={deleteUnit({ vertical: 'bottom', horizontal: 'right' })} autoFocus className='text-[#BD2424]'>
                        {!confirmLoading && <span>Yes</span>}

                        {confirmLoading && loader}
                    </Button>
                </DialogActions>
            </Dialog>
            {success && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {success}
                </Alert>
            </Snackbar>}
            {errorText && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorText}
                </Alert>
            </Snackbar>}
        </>
    )
}

export default PropertyDetails